/**
 * Site header
 */

.noselect {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagecontain {
  max-width: 100%;
  overflow-x: hidden !important;
}
.fullpage {
  height: auto;
  min-height: 100%;
  position: relative;
}

.site-header {
  //border-bottom: 1px solid $faded-grey;
  min-height: 56px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.headshot {
  margin-left: 10px;
  height: 110px;
  margin-top: 20px;
  border-radius: 360px;
  margin-right: 10px;
  margin-bottom: 5px;
  float: left;
}

.site-title {
  font-size: 26px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  padding: 5px;

  &,
  &:visited {
    color: $grey-color-dark;
  }

  &:hover {
    background-color: $faded-grey;
  }

  @include media-query($on-palm) {
    display: block;
    float: none;
    width: fit-content;
    width: -moz-max-content;
  }
}

.site-nav {
  float: right;
  line-height: 40px;
  display: inline-block;

  @include media-query($on-palm) {
    float: left;
    width: 100%;
    margin: 0px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

/**
 * Site footer
 */
.site-footer {
  padding: 5px 0px 0px 0px;
  min-height: 320px;
  padding-top: 30px;
  font-family: "Inter", "sans-serif";
  user-select: none;
  -webkit-user-select: none;

  @include media-query($on-palm) {
    margin-top: 30px;
    .cookie {
      text-align: left;
      line-height: 1.5;
    }
  }
  clear: both;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

.footerTitle {
  font-family: "roboto mono", "roboto", "serif";
  padding-left: 5px;
  font-size: 15px;
}

/**
 * Page content
 */

.portfoliosection {
  clear: both;
  padding-top: 30px;
}

.page-content {
  padding: 0px 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

.glyphright {
  float: right;
  &:hover {
    color: inherit !important;
    background-color: #ffffff !important;
    transition: 0.6s;
  }

  @media screen and (max-width: 600px) {
    color: #000000 !important;
    background-color: #ffffff !important;
    transition: 0.6s;
  }
}

/** Home **/

.gridhome {
  border: 1px solid #f5f5f5;
}

.tblock-index {
  margin-top: 10vh !important;
  @include media-query($on-palm) {
    border: 2px !important;
  }
  margin-bottom: 10vh !important;
}

.terminal-100 {
  width: 100%;
}

.terminalOuter {
  padding: 10px 20px;
  background-color: #313131;
  width: 90%;
  box-shadow: 2px 2px 2px rgba(119, 119, 119, 0.5);
  margin-top: 20px;
}

.terminalblock {
  min-width: 200px;
  color: lime;
  font-family: roboto mono;
  margin-bottom: 20px;
  font-size: 19px;
}

.windowicons-icon {
  background-image: url(/images/windowicons.svg);
  height: 30px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.agile-icon {
  background-image: url(/images/home/agile.svg);
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.torch-icon {
  background-image: url(/images/home/torch.svg);
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.code-icon {
  background-image: url(/images/home/code.svg);
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.home-button {
  padding: 15px 15px;
  transition: 2s ease;
  font-size: 14px;
  font-family: "roboto" !important;
  margin-bottom: 10px;
  margin-right: 5px;
  font-weight: 200;
  background-color: #286aab !important;
  box-shadow: 1px 1px 1px #e0e0e0;
  color: #ffffff !important;
  border-bottom: 1px solid #ffffff !important;

  &:hover {
    box-shadow: 2px 2px 2px #e0e0e0;
    background-color: rgb(59, 149, 239) !important;
    border-bottom: 1px solid #215d98 !important;
  }

  &:visited {
    color: #ffffff !important;
  }
}

.nomargin-nopad {
  margin: 0px;
  padding: 0px;
}

.home-skill-grid {
  margin: 10px 0px;
}

.buttonbottom {
  margin-top: 20px;
}

.leftblockhome {
  // background-color: rgba(80, 80, 80, 0.28);
  // padding: 5px;
  // border: 10px solid rgba(80, 80, 80, 0.08);
}

.backingimage {
  background-image: none !important;
  background-color: #f3f3f3;
}

.me-topline {
  color: #313131;
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 30px;
}

.me-secondline {
  font-weight: 300;
  font-size: 20px;
  margin-top: 5px;
  color: #464646;
}

.terminalsubtext {
  font-size: 14px;
}

.home-left-mini {
  width: 30px;
  display: inline-block;
  margin-right: 30px;
  padding-top: 5px;
}

.home-right-mini {
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
  @include media-query($on-phone) {
    display: block;
  }
}

/**** About page styles ***/

.about-hello {
  font-size: 40px;
  font-weight: 500;
  font-family: roboto mono;
  line-height: 1;
  display: block;
}

.about-intro {
  font-family: roboto mono;
  display: block;
}

.achievebox {
  padding: 10px;
  height: 170px;
  text-align: left;
  color: #fff;
  margin-right: 10px;
  padding: 15px;
  display: block;
  min-width: 125px;
  position: relative;
  margin-bottom: 20px;
}

.achieveimg {
  margin-left: auto;
  margin-right: auto;
  max-height: 50px !important;
  min-width: 10px !important;
  margin-bottom: 6px !important;
  background-repeat: no-repeat !important;
  display: block;
  float: none !important;
  padding: 0px !important;
  width: 50px !important;
  margin: 0 auto;
  margin-left: 0px !important;
}

.achievebold {
  font-weight: 500;
  font-size: 18;
  line-height: 1 !important;
  text-align: left;
  padding-top: 10px;
}

.achievelevel {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 0px !important;
  padding-bottom: 10px;
}

.briefskills {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3 !important;
}

.achievewhen {
  font-size: 12px;
  line-height: 0.2;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bottominfo {
  bottom: 0;
  margin-bottom: 0px !important;
  position: absolute;
  width: 80%;
  left: 15px;
  width: 100%;
}
.grid-achieve {
  margin-right: 60px;
}

.grid-achieve-inpage {
  margin-right: 10px;
}

.exp-grid-experience {
  background-color: #3e3e3e;
  color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}

.simplesplit-mini {
  margin: 10px;
}

.wrapin-exp {
  padding: 0px 10px;
}

.exp-box-icon {
  max-height: 65px !important;
  max-width: 65px !important;
  float: left !important;
  margin: 0 10px 0 0;
  display: block;
}
.exp-head-keyline {
  font-family: "roboto mono";
  font-weight: 600;
  font-size: 17px;
  display: block;
  line-height: 1.4;
}
.exp-head-subline {
  font-size: 15px;
}

.exp-head-bottomline {
  display: block;
  font-size: 13px;
  font-weight: 200;
}

.exp-head-secondtobase {
  display: block;
  font-size: 12px;
  font-weight: 200;
}

.exp-date {
  font-size: 12px;
  text-align: right;
  width: 70px;
  color: #ececec;
}

.exp-imageside {
  float: left;
}

.exp-textside {
  margin: 10px 0px;
  border-left: 1px solid #4a4a4a;
  float: left;
  padding-left: 15px;
}

/**
 * Posts
 */

.post-header {
  margin-bottom: $spacing-unit;
  display: block;
}

.post-title {
  font-size: 32px;
  font-family: "roboto slab";
  color: #545454;
  letter-spacing: -1px;
  line-height: 1.4;
  width: fit-content;
  width: -moz-max-content;
  margin: 0 auto;
  padding: 20px 0px;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  line-height: 1.9;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;
    font-family: $code-font-family;
    color: #424242;
    margin-top: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }

  h5 {
    font-size: 15px;
    font-family: $code-font-family;
    padding: 10px 0px;
    font-weight: 500;
  }

  img {
    padding: 5px;
    width: 350px;
    float: right;
    clear: both;
    margin-left: 20px;
    @include media-query($on-palm) {
      width: 100%;
      margin-left: 0px;
      max-height: none;
      padding: 0px 0px 10px 15px;
    }
  }

  table {
    margin: 0 auto;
    border: 1px solid;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid;
    }
  }

  a {
    border-bottom: 1px dashed;
  }

  .centre-force-blog {
    float: none !important;
    text-align: center;
  }
}

.roundedcorners {
  border-radius: 4px;
}

.precedingnotice {
  font-weight: 300;
  font-size: 10px;
  color: #9a9a9a;
  padding: 10px;
  margin-top: 60px;
  font-family: $code-font-family;
  background-color: #f9f9f9;

  a {
    color: #636363 !important;
  }
}

.postdate {
  color: $grey-black;
  font-family: $code-font-family;
  @include media-query($on-palm) {
    font-size: 10px;
  }
  font-size: 10px;
  display: block;
}

.postimg {
  height: 80px;
  float: right;
  padding-left: 15px;
  padding-top: 10px;
}

.page-social {
  padding-top: 20px;

  a,
  span {
    background-color: #f9f9f9;
    padding: 10px;
    border-color: #dcdcdc;
    font-weight: 100;
    font-size: 10px;
    font-family: "roboto mono";
  }
}

/* MODIFCATIONS BELOW THIS POINT */

/* Header */
.navitem {
  display: inline-block;
  position: relative;
  text-align: right;
  height: 110px;
  width: 55px;
  padding-left: 20px;
  padding-top: 25px;
  border-right: 0.5px solid $faded-grey;
  padding-right: 25px;
  color: $text-color;
  transition: background-color 0.1s, color 0.1s, transform 0.2s 0.1s;

  @include media-query($on-palm) {
    width: 20%;
    padding-left: 0;
    text-align: center;
    height: 30px;
    padding: 12px;
    min-width: 150px;
    margin: 2px 1px;
    display: inline-block;
    border-radius: 3px;
    opacity: 0.95;

    br {
      display: none;
    }
  }

  .fa {
    font-size: 21px;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  .baseline {
    bottom: 0px;
    height: 1px;
    width: 140%;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    margin-left: -20px;

    @include media-query($on-palm) {
      max-width: 105%;
      margin-left: -10%;
    }
  }
}

.navitem:hover > .baseline {
  height: 5px;
  transition: height 0.2s ease-in;
}

.navitem:focus > .baseline {
  height: 5px;
  transition: height 0.2s ease-in;
}

.navitem i {
  @include media-query($on-palm) {
    margin-right: 20px;
  }
}

.nav-text {
  font-size: 18px;
  line-height: 25px;
  padding-top: 25px;

  @include media-query($on-palm) {
    padding-top: 0px;
  }
}

/* Custom colors for menu */
.menu-me {
  &:visited,
  & {
    color: $me-color;
  }

  &:hover {
    color: #ffffff;
    background-color: $me-color;
  }

  @include media-query($on-palm) {
    background-color: $me-color;
    color: #ffffff !important;
  }
}

.warn-topbanner {
  text-align: center;
  background-color: #ff2e2e;
  padding: 4px;
  color: #fff;
}

.menu-posts {
  &:visited,
  & {
    color: $posts-color;
  }

  &:hover {
    color: #ffffff;
    background-color: $posts-color;
  }

  @include media-query($on-palm) {
    background-color: $posts-color;
    color: #ffffff !important;
  }
}

.menu-projects {
  &:visited,
  & {
    color: $project-color;
  }

  &:hover {
    color: #ffffff;
    background-color: $project-color;
  }

  @include media-query($on-palm) {
    background-color: $project-color;
    color: #ffffff !important;
  }
}

.menu-resume {
  &:visited,
  & {
    color: $resume-color;
  }

  &:hover {
    color: #ffffff;
    background-color: $resume-color;
  }

  @include media-query($on-palm) {
    background-color: $resume-color;
    color: #ffffff !important;
  }
}

.menu-search {
  &:visited,
  & {
    color: $search-color;
  }

  &:hover {
    color: #ffffff;
    background-color: $search-color;
  }

  @include media-query($on-palm) {
    background-color: $search-color;
    color: #ffffff !important;
  }
}

.result-block .individual-result {
  border: 0.1px solid #dfdfdf;
}

/* Footer */
.footer-encompass {
  background-color: $lightblue;
  padding: 30px 0;
  color: #ffffff;
  bottom: 0px;
  width: 100%;
  min-height: 35vh;

  a {
    color: #ffffff;
    padding: 5px;
    &:hover {
      text-decoration: none;
      background-color: darken($lightblue, 8%);
    }
  }
}

.footer-list {
  list-style-type: none !important;

  li {
    font-size: 14px;
    padding-top: 3px;
    font-weight: 300;
    margin-top: 5px;
  }
}

.cookie {
  text-align: center;
  background-color: #279bfd;
  color: #9ed2fe;
  padding: 9px 5px;
  margin: 10px 0;
  font-size: 12px;
  margin-top: 50px;
  line-height: 2.8;
  font-weight: 100;
}

/* Search Page */

.search-bar {
  height: 50px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  min-width: 200px;
  padding: 8px;
  font-size: 25px;
}

.searchres-img {
  background-image: url(/images/icons/pretty-bidirectional-cloud.png);
  background-repeat: no-repeat;
  height: 100px;
  background-size: cover;
  float: left;
  width: 100px;
}

.grid-achieve-cv {
  border: 1px solid rgba(62, 62, 62, 0.101) !important;
  margin-right: 20px;
  min-height: 180px;
}

/* Post Page result */

.individual-result {
  margin-bottom: 10px;
  min-height: 150px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 4px;
  background-position: center;

  // &:hover {
  //   margin-right: 2px;
  //   border-right-width: 6px;
  //   transition: border-right-width 0.1s ease, margin-right 0.1s ease;
  // }

  @include media-query($on-palm) {
    height: auto !important;
  }
}

.individual-result-overlay {
  padding: 10px;
  min-height: 150px;
  height: 100%;
  width: auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(24, 24, 24, 0.7) 9%,
    rgba(45, 45, 45, 0.5) 43%,
    rgba(80, 80, 80, 0.4) 100%
  );
}

.safetypad-post {
  padding: 20px 10px;
  border: 1px solid rgb(245, 245, 245);
}

.indres-imageblock {
  float: right;
  height: 80px;
}

.indres-image {
  height: 90px;
  width: 90px;
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  background-position-y: center;
}

.indres-textblock {
  padding-left: 0;
  width: 80%;
  a {
    color: #000000;
  }
  @include media-query($on-palm) {
    width: 50%;
  }
}

.indres-pagetitle {
  font-size: 24px;
  line-height: 1;
  margin-top: -20px;
  font-family: "roboto slab";
}

.postlist {
  list-style-type: none;
  margin-left: 0px;

  a:visited,
  a {
    color: #2c2c2c;
    border-bottom: none !important;
  }
}

.postlink {
  text-decoration: none !important;
}

.postlinkwhite {
  color: #ffffff !important;
}

.indres-block {
  margin-top: 2px;
  display: inline-block;
  padding-left: 10px;
}

.indres-postinfo-container {
  bottom: 10px;
  position: absolute;
  width: calc(100% - 20px);
  float: right;
  text-align: right;
  font-size: 12px;
  left: 0px;
}

.indres-postinfo {
  background-color: rgba(99, 99, 99, 0.07);
  padding: 4px !important;
  font-family: $code-font-family;
}

.indres-pagecat {
  float: right;
  padding-right: 3px;
}

.indres-pagedesc {
  font-size: 15px;
  line-height: 1.6 !important;
  display: block;
  margin-top: 10px;
  max-width: 600px;
  line-height: 1.6;
}

.indres-textbg {
  padding: 10px;
}

/* Post type banners */
.network-banner {
  border-right: 4px solid $network;
}

.life-banner {
  border-right: 4px solid $life;
}

.comment-banner {
  border-right: 4px solid $comment;
}

.logistics-banner {
  border-right: 4px solid $logistics;
}

.short-banner {
  border-right: 4px solid $short;
}

.events-banner {
  border-right: 4px solid $events;
}

.build-banner {
  border-right: 4px solid $build;
}

.network {
  background-color: $network;
}

.life {
  background-color: $life;
}

.comment {
  background-color: $comment;
}

.logistics {
  background-color: $logistics;
}

.short {
  background-color: $short;
}

.events {
  background-color: $events;
}

.build {
  background-color: $build;
}

.network-btop {
  border-top: 5px solid $network;
}

.projects-btop {
  border-top: 0.5px solid #f3f3f3;
}

.life-btop {
  border-top: 5px solid $life;
}

.comment-btop {
  border-top: 5px solid $comment;
}

.logistics-btop {
  border-top: 5px solid $logistics;
}

.short-btop {
  border-top: 5px solid $short;
}

.events-btop {
  border-top: 5px solid $events;
}

.build-btop {
  border-top: 5px solid $build;
}

/* Projects Page */

.slow-spin {
  -webkit-animation: fa-spin 10s infinite linear;
  animation: fa-spin 10s infinite linear;
}

.design-inprogress {
  background-color: #2fa0ff;
  text-align: center;
  padding: 10px;
  color: #fff;
  box-shadow: 4px 2px #d2d2d2;
  margin-bottom: 30px;
}

.individualproject {
  border: 1px solid #ebebeb;
  margin: 10px 10px;
}

.basepoints {
  padding: 10px;
  border-bottom: 4px solid;
  min-height: 210px;
}

.grid-30.grid-33-margin.individualproject:hover > .basepoints.grid-container {
  //border-bottom: 6px solid !important;
  //min-height: 217px;
  transition: 0s;
}

.customcolour-project {
  color: #ffffff;
}

.rightstats {
  float: right;
  width: 270px;
  padding: 10px;
  border-radius: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
    display: inline-block;
    float: none;
    margin: 0px;
    margin-left: -10px;
  }
}

.projectimage {
  height: 220px;
  width: 100%;
  background-size: cover !important;
  background-position-x: center !important;
  background-repeat: no-repeat;
}

.projecttitle {
  display: block;
  padding-right: 20px;
  font-size: 80px;
  line-height: 1.2;
  height: 200px;
  color: #070707;
  text-align: right;
  background-color: rgba(255, 255, 255, 0.48);
  padding-top: 20px;
  padding-left: 20px;
}

.portfoliotitle {
  display: block;
  padding: 20px;
  font-size: 40px;
  line-height: 1.2;
  height: 200px;
  color: #070707;
  text-align: left;

  background-color: rgba(255, 255, 255, 0.7);
}

.project-largetext {
  font-weight: 500;
  padding: 10px;
  text-align: left !important;
}

.minigrid {
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 7px;
  text-align: center;
}

a .individualproject {
  color: #000000 !important;
}

.bottompagekey {
  bottom: 0px;
  width: 100%;
  clear: both;
  margin-top: 30px;
  font-size: 12px;
  background-color: #f8f8f8;
  font-family: $code-font-family;
  padding: 10px;
}

.updateblock {
  padding: 20px;
  background: #f8f8f8;
}

.updatedate {
  padding: 10px 5px;
  background: #f1f1f1;
  margin-bottom: 10px;
}

.post-cat {
  float: right;
  text-align: right;
  font-size: 16px;
  padding-bottom: 2px;
  font-family: $code-font-family;
}

.guestpost {
  padding: 20px 20px;
  clear: both;
  margin: 0 0 20px 0;
  font-family: "roboto mono";
  background-color: #f3f3f3;

  h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.6;
  }

  p {
    font-size: 10px;
  }
}

.glyphpage {
  text-align: right;
  padding-top: 20px;
  font-size: 35px;
  margin-left: 10px;
}

.headerlhs {
  width: 50%;
  display: inline-block;
}

.centerpostimg {
  max-width: 100%;
  max-height: 200px;
  vertical-align: middle;
  padding: 20px;
}

.brandimg {
  height: 80px;
  width: 90%;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.projects-describe {
  line-height: 1.4;
  padding-top: 5px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  margin-top: 5px;
  font-size: 15px;
}

.projects-date {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.outside-links {
  text-align: center;
  line-height: 4;
  margin-top: 30px;
  padding-top: 10px;
}

.outside-link-title {
  font-size: 19px;
  margin-bottom: 30px;
  font-family: "roboto mono";
  font-weight: 500;
}

.projectlogo-page {
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
}

.current-page-ribbon {
  margin-top: 0px;
  margin-bottom: 10px;
}

.title {
  font-weight: 400;
}

.subtitle {
  font-weight: 300;
  font-size: 18px;
}

.titleandsub {
  line-height: 0.9;
  margin-top: 52px;
  display: inline-block;
  font-family: $code-font-family;
  @include media-query($on-laptop) {
    max-width: 200px;
  }
  @include media-query($on-palm) {
    max-width: 200px;
    line-height: 1.2;
    margin-top: 30px;
  }
}

.statusblock {
  padding: 15px 0px;
  border-top: 2px solid #f8f8f8;
  border-bottom: 2px solid #f8f8f8;
  margin: 0px 20px;
  padding: 15px 0px;
}

.updateblock li {
  text-indent: -31px;
  margin-left: 31px;
}

/* 404 Page */

.searchbarerror {
  width: 75%;
  margin: 0 auto;
}

.frame-search {
  max-width: 100%;
  @include media-query($on-palm) {
    max-width: 200px;
  }
}

.errorpagetextblock {
  padding: 10px 30px;
  background-color: $terminalgrey;
  color: $terminalgreen;
  font-family: "Roboto Mono", "Roboto", sans-serif;
  font-weight: 500;
}

.errorpagetext {
  font-size: 25px;
  font-weight: 500;
}

.blinkingarrow {
  animation: blink-animation 0.8s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.8s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* About Page */

.sidebartext {
  padding-left: 20px;
  line-height: 3;
  font-weight: bold;
}

.littlepadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.simplesplit {
  margin: 20px 0;
  border-color: $faded-grey;
}

.linkedintext:hover > .linkedin {
  background-color: #02699e;
  transition-duration: 1s;
}

.linkedin {
  text-align: center;
  color: #ffffff;
  background-color: #0077b5;
  font-weight: 400;
  width: 95%;
  margin-bottom: 20px;

  a {
    color: #ffff;
    text-decoration: none;
  }
}

.resumeblock {
  background-color: #4c6085;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  width: 95%;
  margin-bottom: 20px;

  a {
    color: #ffff;
    text-decoration: none;
  }
}

.resumetextblock:hover > .resumeblock {
  background-color: #556a92;
  transition-duration: 1s;
}

/* Pages - projects */

.fullsize-centreimage {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50%;
  height: auto !important;
  max-height: none !important;
  float: none !important;
}
.portfolio-status {
  font-size: 14px;
}

.project-subtext {
  color: #ffffff;
  border-radius: 6px;
  border-top: 2px solid;
  width: fit-content;
  width: -moz-max-content;
  margin: 0 auto;
  padding: 12px 20px 10px 20px;
}

.portfolioimg {
  max-height: 100px !important;
}

.projecttype {
  padding: 5px 15px;
  color: #fff;
  float: right;
  font-family: "roboto mono";
  font-weight: 500;
}

.projects-bg {
  background-color: rgba(255, 255, 255, 0.75);
  color: #000000;
}

.web-bg {
  background-color: $lightblue;
}

.minigridcircle {
  background-color: rgba(255, 255, 255, 0.6);
  width: 95px;
  height: 90px;
  margin-top: -15px;
  padding: 20px;
  color: #000000;
  float: right;
}

/* Buttons */
.btn {
  padding: 5px;
  max-width: fit-content;
  width: -moz-max-content;
  display: inline-block;
  background-color: #ffffff;
  color: #000000 !important;
  text-align: center;
  font-weight: 500;
  transition-duration: 2s;
  border-bottom: 0px !important;
  font-family: $code-font-family;
  line-height: 3;
  word-break: break-word;

  a {
    color: #000000 !important;
    border-bottom: 0px !important;
  }
  &:hover {
    background-color: $less-faded-grey !important;
    text-decoration: none;
  }
}

.inproject {
  background: #404040 !important;
  color: #ffffff !important;
}

.inpost {
  padding: 5px 15px;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  font-weight: 500;
  transition-duration: 2s;
  border-bottom: 0px !important;
  font-family: $code-font-family;
  box-shadow: 1px 1px 1px #777777;
  margin: 5px;

  a {
    color: #000000 !important;
    border-bottom: 0px !important;
  }
  &:hover {
    background-color: #4a4a4a !important;
    text-decoration: none;
    box-shadow: 2px 2px 2px #777777;
    border-bottom: 0px !important;
    color: #ffffff;
  }
}

.contact {
  border-bottom: 0px;
  color: #000000;
  background-color: #eee;
  padding: 10px;
  margin-top: 10px;
  margin-top: 10px;
  padding: 5px;
  padding: 10px;
  margin-left: 0px;
}

.smallimgright {
  float: right;
  width: 40%;
  min-width: 200px;
  margin-left: 30px;
  border: 20px solid #000;
  border-radius: 10px;
}

.light-home {
  font-weight: 300;
  font-size: 13.1px;
}

/* Code */

div {
  .highlighter-rouge .highlight {
    background: #f4f2f2 !important;
    width: fit-content;
    width: -moz-max-content;
  }
}

.new-post-style {
  margin: 0 auto;
  max-width: 900px;

  img {
    max-height: 400px;
    display: block;
    margin: 0 auto;
    padding: 20px 0px;
    float: none;
    width: auto;
    @include media-query($on-palm) {
      padding: 0;
    }
  }

  figure.highlight {
    max-width: fit-content;
    width: -moz-max-content;
    margin: 0 auto;
    padding: 10px;
  }

  h5 {
    font-size: 19px;
  }

  .center {
    margin: 0;
  }
}

/** Terminal page **/

.essential-why {
  line-height: 1;
  font-size: 13px;
  margin-bottom: 15px;
}

.essential-link {
  font-size: 10px;
  padding: 8px;
  margin-bottom: 10px;
  margin-left: 0px;
  background-color: #dadada;
}

.grid-apps {
  height: 185px !important;
}

.app-bottom {
  margin-bottom: 10px !important;
}

.essential-contain {
  margin-right: 70px;
  min-width: 100px;
}

.backingimage {
  background-size: cover;
}

.subtext {
  font-size: 16px;
  a {
    color: #00ff00;
    border-bottom: 1px dashed;
  }
  a:hover {
    color: #76ff76 !important;
    text-decoration: none;
  }
}

iframe,
video {
  margin: 0 auto;
  display: block;
  border: 10px solid $brand-color;
  width: 80%;
  max-width: 700px;
  clear: both;
}

/* Post Hiatus */
.hiatus {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #efefef;
  border-right: 1px solid #adacac;
  margin-right: 2px;
  text-align: center;
}

.hiatusname {
  font-weight: 500;
  font-size: 20px;
  font-family: $code-font-family;
}

.hiatuswhy {
  font-size: 14px;
  color: #565656;
}

/* For Posts */

.inline-warn {
  padding: 10px;
  display: block;
  font-size: 14px;
  background-color: #f5f5f5;
  color: #403f3f;
}

.post-content h3 {
  font-size: 19px;
  font-weight: 500;
  background-color: #e2e2e2;
  padding: 10px;
}

.post-content h4 {
  font-size: 15px;
  font-weight: 500;
  background-color: #f5f5f5;
  padding: 10px;
}

/* Inline Table */

.tg {
  table-layout: fixed;
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  font-size: 14px;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
}
.tg th {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
}
.tg .tg-yw4l {
  vertical-align: top;
}
.tg img {
  height: auto !important;
}
th,
td {
  padding: 10px !important;
}

// project
.dissassembly-image {
  height: 200px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}

.dissassembly-block {
  background-color: #e8e8e8;
  padding: 20px;
  margin: 3px;
  min-height: 460px;
}
.circuit {
  max-height: 300px;
}

.circuitsmallimg {
  //height: 100px;
  float: right;
  @include media-query($on-palm) {
    height: auto !important;
  }
}

.projectwrap {
  width: 95%;
  margin: 0 auto;
}

// video player
video::-webkit-media-controls {
  filter: hue-rotate(180deg) brightness(0.9);
}

video::-internal-media-controls-download-button {
  display: none;
}
video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}

.northfolk {
  background-color: #3f4451;
  padding: 30px 20px !important;
  color: #ffffff;
  line-height: 1.5;
  font-weight: 200;
  font-size: 14px;
}

.about-background-block {
  height: 300px;
  display: block;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
  margin-top: -10px;
  position: relative;
  min-height: 300px;
}

.about-home-block {
  background-color: #e9eaec99;
  padding: 10px 15px;
  position: absolute;
  bottom: 10px;
  color: #3e3e3e;
}

.post-top-block {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #ffffff;
  position: relative;
  padding-top: 30px;
  padding-left: 0px !important;
}

.whitetext {
  color: #ffffff !important;
}

.post-head-block {
  height: fit-content;
  width: 100%;
  @include media-query($on-palm) {
    height: fit-content;
  }
}

.imagecover {
  width: 100%;
  height: 100%;
  min-height: 300px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(24, 24, 24, 0.7) 9%,
    rgba(45, 45, 45, 0.5) 43%,
    rgba(80, 80, 80, 0.4) 100%
  );
}

.imagecover-project {
  background: linear-gradient(
    360deg,
    rgba(197, 197, 197, 0.7) 0%,
    rgba(171, 171, 171, 0.7) 9%,
    rgba(204, 204, 204, 0.5) 43%,
    rgba(210, 210, 210, 0.4) 100%
  ) !important;
}

.post-icon {
  height: 150px !important;
  width: 100% !important;
  display: block !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: right;
  clear: both;
  background-position-x: right;
  margin-top: 10px;
}

.category {
  color: #ffffff;
  padding: 7px;
  font-family: roboto mono;
  font-size: 15px;
  border-radius: 2px;
}

.rightside-post-header {
  text-align: right;
  display: inline-block;
  float: right;
  width: 20%;
}

.post-head-left {
  max-width: 70%;
  display: inline-block;
}
.post-title-500 {
  font-size: 40px;
  max-width: 650px;
  @include media-query($on-palm) {
    font-size: 30px;
  }
}

.padded-top-post-block {
  margin-top: 20px;
  padding-left: 0px !important;
}

.category-microblock {
  padding: 4px;
  border-radius: 2px;
  color: #ffffff;
}

// guest post

.featured-bookmarkext {
  font-size: 70px;
  color: #949090;
  margin-left: 10px;
}

.featured-base {
  font-size: 19px;
  font-weight: 500;
  //background-color: #efefef;
  padding: 17px;
  display: block;
}

.featured-normal {
  font-size: 14px;
  display: block;
  font-weight: 300;
  margin-top: 15px;
}

.loadMoreBox {
  text-align: center;
}
// Loading - https://loading.io/css/

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-top: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 3px solid #2e9fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2e9fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
