@charset "utf-8";

// Our variables
$base-font-family: "Inter", Helvetica Neue, Arial, sans-serif;
$post-font-family: "Inter", Helvetica Neue, Arial, sans-serif;
$code-font-family: "Roboto mono", "Roboto", "Georgia", serif;

$base-font-size: 17px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit: 20px;

// Terminal Colours
$terminalgreen: rgba(0, 255, 0, 1);
$terminalgrey: #313131;

/* Key Page Colours */
$me-color: #3382cc;
$posts-color: #9355aa;
$search-color: #da2549;
$project-color: #45ba89;
$resume-color: #4c6085;

/* Post Type Color */
$project: #9355aa;
$network: #39c671;
$other: #ed5012;
$portfolio: #f0830f;
$user: #4790b8;
$life: #d4b82b;
$comment: #d72828;
$logistics: #19a5e6;
$short: #b34ccd;
$events: #00abe7;
$build: #f55d3e;

/* Other colors */
$text-color: #444444;
$background-color: #fdfdfd;
$brand-color: $me-color;
$lightblue: #2e9fff; /*the light blue featured in footer */

$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);
$faded-grey: rgba(232, 232, 232, 0.2);
$less-faded-grey: rgba(232, 232, 232, 0.8);

$contrastcolour: #2e9fff;
$grey-black: #424242;

$link-color: #777777;

// Width of the content area
$content-width: 1000px;

$on-phone: 800px;
$on-palm: 910px;
$on-laptop: 1200px;

/* Fetch fonts and external CSS*/
@import url("//fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i,900,900i");
@import url("//fonts.googleapis.com/css?family=Roboto+Mono:300,400,500,700");
@import url("//fonts.googleapis.com/css?family=Roboto+Slab");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,400i,600");
@import url("https://cdn.jsdelivr.net/npm/inter-ui@3.9.0/inter.min.css");
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("/css/font-awesome-animation.css");
@import url("/css/grid.css");
@import url("/css/animate.css");

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "base", "layout", "syntax-highlighting";
